import React from 'react'

import {
  Help,
  List,
  ListItem,
  Paragraph,
  Strong,
  TimeInput
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Color } from '../../components/Color'
import { Link } from '../../components/Link'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import { TimeInputErrorExample } from '../../examples/react/TimeInputErrorExample'
import { TimeInputExample } from '../../examples/react/TimeInputExample'
import { TimeInputMinMaxExample } from '../../examples/react/TimeInputMinMaxExample'
import { TimeInputMinuteStepExample } from '../../examples/react/TimeInputMinuteStepExample'
import { TimeInputMultipleExample } from '../../examples/react/TimeInputMultipleExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="TimeInput"
    components={[{ component: TimeInput }]}
    status={[{ type: 'accessible', version: '16.3.0' }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          <Code>TimeInput</Code> on yhdistelmä tekstikentästä ja valikosta
          kellonajan syöttämiseen.
        </ListItem>
        <ListItem>
          Tekstikenttään on mahdollista syöttää vain numeroita (<Code>0</Code>-
          <Code>9</Code>) ja kaksoispisteitä (<Code>:</Code>).
        </ListItem>
        <ListItem>Tekstikenttä on rajattu viiteen merkkiin.</ListItem>
      </List>

      <Help>
        <Paragraph>
          <strong>Muutos Style Guide v.12.0.0</strong>: saavutettavuuden vuoksi
          luovutaan placeholderista kentän formaatin ohjeistuksessa. Jatkossa
          ohjeistus tulee sijoittaa subLabeliin. Tämän vuoksi{' '}
          <Code>TimeInput</Code>-komponentin sisäisestä placeholderista on
          luovuttu.
        </Paragraph>
        <Paragraph noMargin>
          Muutosohje: lisätään kellonajan (kieliversioitu) ohjeistus{' '}
          <Code>subLabel</Code>iin.
        </Paragraph>
      </Help>
    </Section>
    <Section title="Esimerkkejä">
      <Playground
        enableOverflow
        example={TimeInputExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Minimi ja maksimi">
      <Paragraph noMargin>
        Valittavissa olevat tunnit voidaan rajoittaa esimerkiksi virka-aikaan
        käyttämällä <Code>minHours</Code> ja <Code>maxHours</Code> -proppeja.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Rajoitusta käytettäessä on asetettava molemmat arvot.
        </ListItem>
        <ListItem>
          Minimi- ja maksimiarvot eivät rajoita kenttään kirjoitettavaa
          kellonaikaa, ainoastaan pudotusvalikossa näytettäviä arvoja.
        </ListItem>
        <ListItem>
          Minimi- ja maksimiarvot tulee validoida manuaalisesti muun validoinnin
          ohessa.
        </ListItem>
      </List>
      <Paragraph>
        <Strong>Saavutettavuus:</Strong> sublabelissa tulee sanallisesti kertoa,
        miltä aikaväliltä kellonaika tulee valita, jos syötettä validoidaan.
      </Paragraph>
      <Playground
        enableOverflow
        example={TimeInputMinMaxExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Minuuttivalinta">
      <Paragraph noMargin>
        Valittavissa olevat minuutit voidaan näyttää tietyin välein käyttämällä{' '}
        <Code>minuteStep</Code>-proppia. Esimerkiksi arvolla{' '}
        <Code>minuteStep=15</Code> minuuttivalikossa näytetään vaihtoehdot 00,
        15, 30 ja 45.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          MinuteStep-arvo ei rajoita kenttään kirjoitettavaa kellonaikaa,
          ainoastaan pudotusvalikossa näytettäviä arvoja.
        </ListItem>
        <ListItem>
          Minuuttilukema tulee validoida manuaalisesti muun validoinnin ohessa,
          jos <Code>minuteStep</Code>-rajoituksesta poikkeavia arvoja ei
          sallita.
        </ListItem>
      </List>
      <Paragraph>
        <Strong>Saavutettavuus:</Strong> sublabelissa tulee sanallisesti kertoa,
        millaiset minuuttivalinnat ovat sallittuja, jos syötettä validoidaan sen
        suhteen.
      </Paragraph>
      <Playground
        enableOverflow
        example={TimeInputMinuteStepExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Virheviesti">
      <List variant="unordered">
        <ListItem>
          Komponentille annettu arvo tulee validoida ja passata sen mukainen
          virheviesti <Code>error</Code>-propille. <Code>TimeInput</Code> ei tee
          mitään validointia.
        </ListItem>
        <ListItem>
          Virheviestiä käyttäessä inputin reunaviiva näytetään{' '}
          <Color color="danger" />
          -värillä.
        </ListItem>
      </List>
      <Playground
        enableOverflow
        example={TimeInputErrorExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Monta kenttää, yhteinen subLabel">
      <Paragraph>
        Toiston välttämiseksi (sekä visuaalisesti että ruudunlukijalla luettuna)
        esitetään ohjeistus ryhmän yhteisessä subLabelissa.
      </Paragraph>
      <Playground
        enableOverflow
        example={TimeInputMultipleExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Disabloitu">
      <Paragraph>
        Ei-muokattava tieto tulisi yleensä esittää{' '}
        <Code>
          <Link page="Detail" />
        </Code>
        -komponentilla. Jos tieto on väliaikaisesti disabloitu (esim. lomakkeen
        muut valinnat vaikuttavat siihen, tarvitseeko tietoa syöttää), silloin
        voidaan käyttää disabloitua kenttää. Ruudunlukijalla disabled-kenttä
        hypätään yli.
      </Paragraph>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <TimeInput
          disabled
          help="Help"
          label="Aika"
          subLabel="tt:mm"
        />
      </Playground>
    </Section>
    <Section title="Lukittu">
      <Paragraph>
        Read-only-kenttää ei suositella käytettäväksi, vaan tulisi käyttää{' '}
        <Code>
          <Link page="Detail" />
        </Code>
        -komponenttia tai joissakin tapauksissa disabloitua. Lukittu kenttä on
        ruudunlukijalle hämmentävä, sillä se kuulostaa tavalliselta
        täytettävältä kentältä, read only -tieto luetaan ruudunlukijalla vasta
        viimeisenä kenttään tultaessa.
      </Paragraph>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <TimeInput
          label="Aika"
          readOnly
          value="12:34"
        />
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>Älä käytä placeholderia komponentissa.</ListItem>
        <ListItem>
          Sublabelissa pitää olla ohjeistus, missä muodossa aika pitää syöttää.
        </ListItem>
        <ListItem>
          Varmista, että virheteksti auttaa käyttäjää korjaamaan virheen oikein.
        </ListItem>
        <ListItem>
          Jos komponentteja ryhmitellään, varmista että jokaiselle komponentille
          on oma virheentarkistus.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
