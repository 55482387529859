import React, { ComponentProps, useState } from 'react'
import { FormGroup, TimeInput } from '@te-digi/styleguide'

const TimeInputErrorExample = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof TimeInput>['value']>('25:67')

  return (
    <FormGroup noMargin>
      <TimeInput
        error="Error"
        label="Aika"
        subLabel="tt:mm"
        onChange={e => setValue(e)}
        value={value}
      />
    </FormGroup>
  )
}

export { TimeInputErrorExample }
