import React, { ComponentProps, useState } from 'react'
import { FormGroup, FormText, TimeInput } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

const TimeInputExample = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof TimeInput>['value']>('')

  return (
    <FormGroup noMargin>
      <TimeInput
        label="Aika"
        onChange={e => setValue(e)}
        onSelection={val => console.log('TimeInput onSelection', val)}
        required
        subLabel="tt:mm"
        value={value}
      />
      <FormText>
        Arvo: <Code>{JSON.stringify(value)}</Code>
      </FormText>
    </FormGroup>
  )
}

export { TimeInputExample }
