import React, { ComponentProps, useState } from 'react'
import { FormGroup, FormText, TimeInput } from '@te-digi/styleguide'

import { Code } from '../../components/Code'

const TimeInputMinMaxExample = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof TimeInput>['value']>('')

  return (
    <FormGroup noMargin>
      <TimeInput
        label="Aika"
        subLabel="Ilmoita aika muodossa tt:mm, välillä 07:00-17:00"
        onChange={e => setValue(e)}
        onSelection={val => console.log('TimeInput onSelection', val)}
        minHours={7}
        maxHours={17}
        value={value}
      />
      <FormText>
        Arvo: <Code>{JSON.stringify(value)}</Code>
      </FormText>
    </FormGroup>
  )
}

export { TimeInputMinMaxExample }
